<template>
  <v-card-text id="richiesta-reset-password" v-if="! richiestaInviata">
    <p>Inserire il codice fiscale e cliccare sul pulsante per richiedere il reset della password.</p>
    <v-text-field          
      v-model="cfInput"  
      label="Codice Fiscale"
      name="cf-reset"
      counter
      :rules="[rules.required]"
      maxlength="16"
      value=""        
      rounded    
      @keyup.13="$emit('reset', cfInput)"/>

    <v-card-actions id="">
      <v-btn text rounded @click.prevent="$emit('reset', cfInput)">RICHIESTA RESET PASSWORD</v-btn>
    </v-card-actions>
    <a v-if="formLogin == true" id="reset-password-torna-login" href="#" @click="$emit('richiediReset', false)">Tornare al Login</a>
  </v-card-text> 
  <v-card-text id="richiesta-reset-password" v-else>
    <p>La richiesta è stata inviata correttamente. A breve riceverete un'email con le istruzioni per il reset delle credenziali.</p>
    <p>E' ora possibile chiudere la pagina.</p>
  </v-card-text>
</template>
<script>

const NAME = 'FormRichiediResetSpSa'
const CAMPO_OBBLIGATORIO = 'Campo obbligatorio'

export default {
  name: NAME,
  props: ['formLogin', 'richiestaInviata', 'messages'],
  data(){
    return { 
      NAME: NAME,
      datiUtente: {},
      cfInput: null,
      rules: {
        required: v => !!v || 'Inserire il codice fiscale.'
      }
    }
  },
  computed: {
    msgs() {
      return {
        requiredUserName: this.messages.requiredUserName || CAMPO_OBBLIGATORIO,
        requiredPassword: this.messages.requiredPassword || CAMPO_OBBLIGATORIO
      }
    }
  }
}
</script>
<style src="@/styles/custom/components/login/_form_richiedi_reset.scss" lang="scss"></style>